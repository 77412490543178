@import "~antd/dist/antd.css";

body { overflowY: hidden; /* Hide scrollbars */ } 

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuBar {
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 250px;
  float: left;
  height: 100%;
}
.logo a {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.menuCon {
  width: calc(100% - 250px);
  float: left;
}
.menuCon .ant-menu-item {
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}
.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}
.menuCon .ant-menu-horizontal {
  border-bottom: none;
}
.menuCon .leftMenu {
  float: left;
}
.menuCon .rightMenu {
  float: right;
}
.barsMenu {
  float: right;
  height: 32px;
  margin-right: 10px;
  display: none;
  background: none;
  border: none;
  box-shadow: none;
}
.barsMenu:hover {
  background-color: none;
}

.barsMenu:hover {
  background-color: none;
}

.ant-drawer-body {
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

/* training/onboarding flow*/
.training-container {
  width: 90vw;
}

#tips-box-container {
  position: absolute;
}

#training-login-instructions-text {
  padding-bottom: 15px;
}

.step-container {
  padding: 5vh;
}

.training-step-header,
.training-step-subheader {
  max-width: 50vw;
}

.training-step-header {
  font-weight: 800;
  font-size: 32px;
  padding: 20px 0 20px;
  text-align: left;
}

.train-step-subheader {
  font-weight: 500;
  font-size: 20px;
}

.training-image-instruction-step {
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding: 0 24px;
}

#training-schedule-interview-container {
  padding-top: 50px;
}

#training-click-schedule-text {
  margin: 20px 0;
}

.training-date-filter-vertical-spacer {
  height: 200px;
}

.training-menu-parent {
  background-color: white;
  box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2);
}

.training-menu-child {
  padding: 0 10px;
  width: 400px;
}

.training-start-button-container {
  padding-top: 80px;
}

.start-training-button {
  margin: 0 0 44px;
}

/* end training/onboarding flow */

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }

  /* training/onboarding flow*/
  .training-container {
    width: 100%;
  }

  #tips-box-container {
    position: static;
    margin: 44px 0;
  }

  .training-images-container {
    flex-direction: column;
    align-items: center;
  }

  .training-step-header,
  .training-step-subheader {
    text-align: center;
    max-width: 100%;
    text-align: left;
    padding: 24px 16px 0;
  }

  .training-step-header {
    font-size: 24px;
  }

  .training-step-subheader {
    font-size: 18px;
  }

  .training-header-container {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .step-container {
    padding: 10px;
  }

  .start-training-button {
    margin: 80px 0 40px;
  }

  .training-image {
    margin: 0 0 30px;
    max-width: 80vw;
  }

  .hidden-mobile {
    display: none;
  }

  .mobile-padding-bottom-20px {
    padding-bottom: 20px;
  }

  /* .mobile-margin-right-40px {
    margin-right: 40px;
  } */

  .training-date-filter-vertical-spacer {
    height: 24px;
  }

  .training-menu-parent {
    background-color: transparent;
    box-shadow: none;
  }

  .training-start-button-container {
    padding-top: 40px;
  }

  .training-menu-child {
    width: 100%;
    padding: 10px 40px;
    position: absolute;
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2);
  }

  .training-login-container {
    justify-content: center;
    align-items: center;
  }

  #training-web-alert-image {
    margin-left: 24px;
  }

  #training-email-alert-image {
    margin-left: 32px;
  }

  #training-schedule-button-image {
    margin-right: 36px;
  }

  #training-turn-off-alerts-img {
    margin-right: 44px;
  }
  #training-date-filters-img {
    padding-bottom: 36px;
  }
  /* end training/onboarding flow */
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* training/onboarding flow*/
  #tips-box-container {
    position: static;
    margin: 44px;
  }

  .training-images-container {
    flex-direction: column;
    align-items: center;
  }

  .training-step-header,
  .training-step-subheader {
    text-align: center;
    max-width: 70vw;
  }

  .training-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step-container {
    padding: 10px;
  }

  .training-start-button-container {
    padding-top: 40px;
  }

  .start-training-button {
    margin: 80px 0 40px;
  }

  .training-image {
    margin: 0 0 30px;
    max-width: 60vw;
  }

  .hidden-mobile {
    display: none;
  }

  .mobile-padding-bottom-20px {
    padding-bottom: 20px;
  }

  .mobile-margin-right-40px {
    margin-right: 40px;
  }

  .training-image-instruction-step {
    margin: 15px 0;
  }

  .training-date-filter-vertical-spacer {
    height: 24px;
  }

  .training-menu-parent {
    background-color: transparent;
    box-shadow: none;
  }

  .training-menu-child {
    width: 100%;
    padding: 10px 40px;
    position: absolute;
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.2);
  }

  .training-login-container {
    justify-content: center;
    align-items: center;
  }

  .training-phone-img {
    object-fit: contain;
  }

  #training-web-alert-image {
    margin-left: 24px;
  }

  #training-email-alert-image {
    margin-left: 32px;
  }

  #training-schedule-button-image {
    margin-right: 36px;
  }

  #training-turn-off-alerts-img {
    margin-right: 44px;
  }

  #training-date-filters-img {
    padding-bottom: 36px;
  }
  /* end training/onboarding flow */
}
